<template>
  <div class="stepContent commonClass">
    <h3 class="titHead">给药记录</h3>
    <div class="detail">
      <div class="flex  backReturn">
        <h4 class="title">{{ dose.createTime | formatDay }} 给药复核</h4>
        <div>
          <el-button size="small" plain class="return">导出</el-button>
          <el-button size="small" plain class="return" @click="$emit('changePage', 1,null,1)">返回列表页</el-button>
        </div>
      </div>
      <table class="table specTable">
        <tr>
          <th colspan="2">方案编号</th>
          <td colspan="2">{{ activeInfo.number }}</td>
          <th colspan="2">动物品系</th>
          <td colspan="2">{{ activeInfo.strain }}</td>
        </tr>
        <tr style="height: 10px"></tr>
        <tr>
          <td>组别</td>
          <td>笼号</td>
          <td>动物编号</td>
          <td>耳号</td>
          <td>性别</td>
          <td>给药体积 (μL)</td>
          <td>给药</td>
          <td colspan="2">备注</td>
        </tr>
        <template v-for="item in groupMap">
          <tr v-for="(obj,index) in item">
<!--            :rowspan="item.length" v-if="index==0"-->
            <td :rowspan="item.length" v-if="index==0">{{ obj.groupName }}</td>
            <td >{{ obj.cageNo }}</td>
            <td>{{ obj.aniNo }}</td>
            <td>{{ obj.overbit }}</td>
            <td>{{ obj.gender == 1 ? '♂' : '♀' }}</td>
            <td>{{ dose.volume }}</td>
            <td>{{ obj.isDose == 1 ? '√' : '×' }}</td>
            <td colspan="2">{{ obj.remark }}</td>
          </tr>
        </template>
        <tr>
          <td colspan="2">操作人</td>
          <td colspan="2">{{ dose.createName }}</td>
          <td colspan="2">日期</td>
          <td colspan="2">{{ dose.createTime | formatDay }}</td>
        </tr>
        <tr>
          <td colspan="2">复核人</td>
          <td colspan="2">{{ dose.reviewName }}</td>
          <td colspan="2">日期</td>
          <td colspan="2">{{ dose.reviewDate | formatDay }}</td>
        </tr>
      </table>
      <template v-if="state==1&&checkState">
        <h4 class="title">复核结果</h4>
        <el-form :model="doseForm" ref="doseForm" size="small" class="addForm" label-suffix=":"
                 label-width="90px">
          <el-form-item label="复核结果">
            <el-radio-group v-model="doseForm.state" class="radioGroup">
              <el-radio :label="2">复核通过</el-radio>
              <el-radio :label="3">返回修改(复核意见必填)</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="复核意见" prop="reviewRemark"
                        :rules="validateType">
            <el-input type="textarea" rows="4" placeholder="请输入复核意见" v-model="doseForm.reviewRemark"></el-input>
          </el-form-item>
          <div class="fromSave">
            <el-button type="primary" size="small" @click="checkSubmit('doseForm')">复核完成</el-button>
          </div>
        </el-form>
      </template>
      <template v-else-if="state==0||state==1&&!checkState"></template>
      <template v-else>
        <h4 class="title">复核结果</h4>
        <div class="desc">
          <p>复核结果：{{ dose.state == 2 ? '复核通过' : dose.state == 3 ? '复核拒绝' : '' }}</p>
          <p>复核意见：{{ dose.reviewRemark }}</p>
          <span>复核人：{{ dose.reviewName }}</span>
          <span>复核日期：{{ dose.reviewDate | formatDay }}</span>
        </div>
      </template>
    </div>
  </div>


</template>

<script>
import {mapState} from "vuex";
import moment from "moment";
import XLSX from "xlsx";

export default {
  name: "drugEnd",
  props: ['id', 'drugId'],
  data() {
    return {
      activeInfo: {},
      doseForm: {
        state: 2,
        reviewRemark: "",
      },
      state: null,
      checkState: false,
      groupMap: {},
      dose: {},
    }
  },
  computed: {
    ...mapState({
      user: state => state.account.user
    }),
    validateType() {
      return this.doseForm.state === 3
          ? [{required: true, message: '请输入复核意见', trigger: 'blur'}]
          : []
    },
  },
  mounted() {
    this.$get("/subject/" + this.id).then((res) => {
      if (res.status == 200) {
        this.activeInfo = res.data
      }
    })
    this.getData()
  },
  methods: {
    // 获取记录详情
    getData() {
      this.$get('/subject/dose/' + this.drugId).then(res => {
        if (res.status == 200) {
          // 复核人
          this.state = res.data.dose.state
          if (this.user.userId == res.data.dose.reviewer) {
            this.checkState = true
          }
          // 基本信息
          this.dose = res.data.dose
          // 表格
          this.groupMap = res.data.groupMap
        }
      })
    },
    // 提交复核
    checkSubmit(checkForm) {
      this.$refs[checkForm].validate((valid) => {
        if (valid) {
          this.doseForm.id = this.drugId
          this.$put('/subject/dose/state', this.doseForm).then(res => {
            if (res.status == 200) {
              this.$message.success('提交成功')
              this.$emit('changePage', 1, null, 1)
            }
          })
        } else {
          return false;
        }
      })
    },
    // 导出
    exportExcel() {
      let name = moment(this.dose.createTime).format('YYYY-MM-DD') + '给药复核表';
      let wb = XLSX.utils.table_to_book(document.querySelector(".table"));
      let wbout = XLSX.write(wb, {
        bookType: "xlsx",
        bookSST: true,
        type: "array"
      });
      try {
        //  name+'.xlsx'表示导出的excel表格名字
        FileSaver.saveAs(
            new Blob([wbout], {type: "application/octet-stream"}),
            name + ".xlsx"
        );
      } catch (e) {
        if (typeof console !== "undefined") console.log(e, wbout);
      }
      return wbout;
    },
  },
}
</script>

<style lang="scss" scoped>
@import "../../../../assets/css/exper/experiDetail";

.tabsCage {
  margin-left: 0;
}

</style>
